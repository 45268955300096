<template>
    <div></div>
</template>
<script>
    export default {
        created() {
            if (this.$store.state.user.userData.user_role === 'admin') this.$router.push({name: 'admin-research-papers'})
            else if (this.$store.state.user.userData.user_role === 'sola') this.$router.push({name: 'school-research-papers'})
            else this.$router.push({name: 'login'})
        }
    }
</script>